import React from "react"

import AboutSide from "components/Pages/AboutPage/AboutSide";


const AboutPage = () => (
  <>

    <AboutSide />

  </>
)

export default AboutPage

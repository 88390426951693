import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Title from 'components/Elemnts/Title';


const StyleWrapper = styled.div`
  width: 100%;
  height: auto;
  min-height: 900px;
  max-height: 1200px;
  font-family: ${({ theme }) => theme.font.family.Montserrat};
  ${({ theme }) => theme.media.desktop} {
    height: auto;
  }
`;

const StyledInner = styled.div`
  width: 80%;
  height: auto;
  max-width: 1450px;
  margin: 0 auto;
  display: flex;

  justify-content: flex-end;
  align-content: center;
  ${({ theme }) => theme.media.desktop} {
    width: 80%;
    margin: 0 auto;
  }
`;

const StyledP = styled.p`
  font-family: ${({ theme }) => theme.font.family.Montserrat};
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: 500;
  margin: 25px 0;
  line-height: 1.4;
  padding: 0 10px;
  ${({ theme }) => theme.media.desktop} {
    font-family: ${({ theme }) => theme.font.family.Open};
    line-height: 1.5;
    font-size: ${({ theme }) => theme.font.size.s};
    margin: 25px 0px 20px 25px;
  }
`;

const IMG = styled.img`
  display: none;

  ${({ theme }) => theme.media.desktop} {
    display: block;
    width: 450px;
    height: auto;
  }
`;
const StyledIMG = styled.div`
  display: none;

  ${({ theme }) => theme.media.desktop} {
    width: 45%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledDes = styled.div`
  width: 100%;
  height: auto;

  ${({ theme }) => theme.media.desktop} {
    width: 55%;
    height: 680px;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }
`;

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      datoCmsAbout {
        id
        title
        description {
          ... on DatoCmsText {
            id
            textContent
          }
          ... on DatoCmsHeadtitle {
            id
            headtitle
          }
          ... on DatoCmsImg {
            id
            img {
              url
            }
          }
        }
        fotoAbout {
          url
        }
      }
    }
  `);

  return (

    <StyleWrapper>

      <StyledInner>
        <StyledDes>
          <Title>
            <h1>{data.datoCmsAbout.title}</h1>
          </Title>
          <StyledP>
            {data.datoCmsAbout.description.map(item => {
              const itemKey = Object.keys(item)[1];

              switch (itemKey) {
                case 'textContent':
                  return <p key={item.id}>{item[itemKey]}</p>;
                case 'headtitle':
                  return <p key={item.id}>{item[itemKey]}</p>;
                case 'img':
                  return <p key={item.id}>{item[itemKey].url}</p>;

                default:
                  return null;
              }
            })}
          </StyledP>
        </StyledDes>
        <StyledIMG>
          <IMG src={data.datoCmsAbout.fotoAbout.url} alt="a" />
        </StyledIMG>
      </StyledInner>

    </StyleWrapper>
  );
};

export default AboutPage;
